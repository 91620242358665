<template>
  <el-dialog
    title="映射网站和广告账户的(购买、填写信息)事件"
    :visible.sync="dialogVisible"
    width="500px"
  >
    <el-form :model="form" label-width="80px">
      <el-form-item label="购买">
        <el-select v-model="form.PURCHASE" clearable placeholder="请选择">
          <el-option
            v-for="(item, index) in events"
            :label="item.name"
            :value="item.resource_name"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="填写信息">
        <el-select v-model="form.CHECKOUT" clearable placeholder="请选择">
          <el-option
            v-for="(item, index) in events"
            :label="item.name"
            :value="item.resource_name"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handelConfirm">
        映射转化操作
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.form = {}
      }
    }
  },
  methods: {
    handelConfirm() {
      const list = [];
      if (!this.form.PURCHASE) {
        return this.$message.error('请选择购买事件')
      }
      if (!this.form.CHECKOUT) {
        return this.$message.error('请选择填写信息事件')
      }
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          list.push({
            conversionType: key,
            resourceName: value,
          });
        }
      }
      this.$emit("confirm", list);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>