export default function clipboard(text) {
    const textString = text.toString();
    let input = document.querySelector('#copy-input');
    if (!input) {
        input = document.createElement('input');
        input.id = "copy-input";
        input.readOnly = "readOnly";
        input.style.position = "absolute";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.appendChild(input)
    }

    input.value = textString;
    selectText(input, 0, textString.length);
    return new Promise((resove, reject) => {
        input.blur();
        if (document.execCommand) {
            const bool = document.execCommand('copy')
            bool ? resove(bool) : reject(bool)
        } else {
            reject()
        }
    })
};


function selectText(textBox, startIndex, endIndex) {
    if (textBox.createTextRange) { //ie
        const range = textBox.createTextRange();
        range.collapse(true);
        range.moveStart('character', startIndex);
        range.moveEnd('character', endIndex - startIndex);
        range.select();
    } else {
        textBox.setSelectionRange(startIndex, endIndex);
        textBox.focus();
    }
}