<template>
  <div>
    <el-dialog title="账户设置" :visible.sync="dialogVisible">
      <el-form :model="form" label-width="80px">
        <el-form-item label="账户名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="显示/隐藏">
          <el-switch
            active-color="#ff4949"
            inactive-color="#13ce66"
            v-model="form.hide"
          ></el-switch>
        </el-form-item>
        <el-form-item label="主域">
          <el-tag
            :key="tag"
            v-for="tag in domains"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+添加主域</el-button
          >
        </el-form-item>
        <el-form-item label="标签">
          <el-select
            v-model="form.setTag"
            clearable
            multiple
            placeholder="标签选择"
          >
            <el-option
              v-for="(item, index) in tags"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handelConfirm">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
      default: () => ({}),
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      domains: [],
      inputValue: "",
      inputVisible: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.form = {
          name: this.account.name,
          hide: this.account.hide,
          setTag: this.account.tags.map(v => v.id),
        };
        this.domains = [...this.account.domains];
      }
    },
  },
  methods: {
    handelConfirm() {
      const data = {
        ...this.form,
        setDomain: this.domains,
      };
      this.$emit("confirm", data);
    },
    handleClose(tag) {
      this.domains.splice(this.domains.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.domains.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 200px;
  vertical-align: bottom;
}
</style>