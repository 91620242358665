import { render, staticRenderFns } from "./batchSetting.vue?vue&type=template&id=25d49ac1&scoped=true"
import script from "./batchSetting.vue?vue&type=script&lang=js"
export * from "./batchSetting.vue?vue&type=script&lang=js"
import style0 from "./batchSetting.vue?vue&type=style&index=0&id=25d49ac1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d49ac1",
  null
  
)

export default component.exports